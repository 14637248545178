import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Main skills",
        tab3 = "Experience",
        tab4 = "Education & Certification";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="/service">Frontend Development</a>
                                                    HTML, CSS, Bootstrap, Tailwind,Javascript, React , Next, Vue, LWC, Redux, XState, React Native, Third party integrations (Authentication, Payment platforms, CMS etc) 
                                                </li>
                                                <li>
                                                    <a href="/service">Backend Development</a>
                                                    Node Js, Express, Rest, GraphQL, SQL, No SQL.
                                                </li>
                                                <li>
                                                    <a href="/service">Other</a>
                                                    Linux, Aws, Azure Devops, TypeScript
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>



                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Remote Fullstack Developer<span> - Hello Build. Miami, Usa.</span></a> August 2019 - Current
                                               </li>
                                               <li>
                                                   <a href="/service">Fullstack Developer<span> - Intelloidea. Bogota, Colombia</span></a> April 2019 - August 2019
                                               </li>
                                               <li>
                                                   <a href="/service">Remote Fullstack Developer<span> - Togezer. Marseille, France </span></a> September 2018 - March 2018
                                               </li>
                                               <li>
                                                   <a href="/service">Teaching Assistant Unconventional Reservoir Engineering<span> - Universidad de los Andes. Bogota, Colombia</span></a> June 2017 - July 2017
                                               </li>
                                               <li>
                                                   <a href="/service">Geophysical surveys numerical processing<span> - Universidad Nacional de Colombia. Bogota, Colombia. </span></a> January 2016 - December 2017
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Associate Degree Analysis and Development of Information Systems<span> - Servicio Nacional de Aprendizaje. Bogota, Colombia.</span></a> 2010
                                               </li>
                                               <li>
                                                   <a href="/service">Master in Petroleum Engineering<span> - Universidad de los Andes. Bogota, Colombia.</span></a> 2016 - 2018
                                               </li>
                                               <li>
                                                   <a href="/service">Candidate Master Of Science Geophysics<span> - Universidad Nacional de Colombia. Bogota, Colombia.</span></a> 2016 - 2018
                                               </li>
                                               <li>
                                                   <a href="/service">BSc in Geology<span> - Universidad Nacional de Colombia. Bogota, Colombia. </span></a> 2010 - 2015
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;