import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import {Link} from "react-router-dom";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo"
import Footer from "../component/footer/Footer"
import Brand from "../elements/Brand";
import PortfolioList from "../elements/portfolio/PortfolioList";
import TabTwo from "../elements/tab/TabTwo";
import ContactOne from "../elements/contact/ContactOne";
import Helmet from "../component/common/Helmet";

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Fullstack Web Developer',
        title: 'Hello, I’m <span>Juan Rojas</span>.',
        description: 'Welcome to my personal website.',
        buttonText: '',
        buttonLink: ''
    }
]
class PersonalPortfolio extends Component{
    render(){
        let title = 'About Me',
        description = "Hi, my name is Juan Sebastian Rojas. I'm an enthusiastic Javascript Full Stack Web Developer and React Native mobile developer from Bogota, Colombia. My goal is to make real ideas from all around the world with new technologies, trends and industry advancements.";
        return(
            <Fragment> 
                <Helmet pageTitle="Juan Rojas Portfolio" />
                
                <Header logo="symbol-dark" color="color-black"/> 
                
                {/* Start Slider Area   */}
                <div className="slider-wrapper color-black">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--16" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <p className="description">{value.category}</p> : ''}
                                            {value.title ? <h1 className=" description title" dangerouslySetInnerHTML={{__html: value.title}}></h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div className="about-area about-position-top pb--120  bg_color--1" id="about">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5 text-center">
                                    <img className="rounded-circle" height="200" width="200" src="/assets/images/0.jpeg" alt="About Images"/>   
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner pt--100">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                            <a className="mr-3 description" href="https://drive.google.com/file/d/1GhPZ5Ex6IIUHqOh9KPzG7XB1WANyU0Tt/view?usp=sharing" target="blank"><u>Download CV</u></a>
                                            <a className="mr-3" href="https://co.linkedin.com/in/juan-sebastian-rojas-navia-aa727b159/en" target="blank"><u>Checkout my linkedIn</u></a>
                                            <a href="https://github.com/jsrojasn" target="blank"><u>Checkout my Github</u></a>
                                        </div>
                                        <div className="row mt--30">
                                            <TabTwo tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Brand Area */}
                <div className="rn-brand-area pb--120 bg_color--1" id="projects">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h2 className="title">My Latest Projects</h2>
                                    <p>Here are some of my latest comercial projects. Please contact me if you are interested to check other comercial projects where I have been involved. Here are some training projects I have built. <a className="mr-3 description" href="/training" target="blank"><u>Training Projects</u></a> </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--40 mt_sm--5 mt_md--5">
                                <div className="row">
                                    <div className="col-md-6 col-lg-4">
                                        <div className="">
                                            <video width="320" height="240" controls>
                                                <source src="/assets/images/togezer.mp4" type="video/mp4"/>
                                                 Your browser does not support the video tag.
                                            </video>
                                        </div>
							            <div className="p-3">
                                            <div className="blog-post-title margin-bottom-10">
                                                <h3><a href="/">Togezer Web Platform</a></h3>		
                                            </div>
								            <p>Web platform built for the tourism french company Togezer Travel.</p>
							            </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="pt-3">
                                                <a href="https://www.intelloidea.com/" target="blank">
                                                <img src="/assets/images/Intelloidea.jpg" alt="" width="320" height="240" />   
                                                </a>
                                        </div>
							            <div className="p-3">
                                            <div className="blog-post-title margin-bottom-10">
                                                <h3><a href="https://www.intelloidea.com/" target="blank">IntelloIdea</a></h3>		
                                            </div>
								            <p>Web page for tech colombian company IntelloIdea.</p>
								            
							            </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="pt-3">
                                                <a href="https://www.careercircle.com/" target="blank">
                                                    <img src="/assets/images/CC.jpg" alt=""/>
                                                </a>
                                        </div>
							            <div className="p-3">
                                            <div className="blog-post-title margin-bottom-10">
                                                <h3><a href="https://www.careercircle.com/" target="blank">Career Circle</a></h3>		
                                            </div>
								            <p>Web page for staffing, recruiting and profesional development.</p>
							            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div> 
                {/* End Brand Area */}

                {/* Start Brand Area */}
                {/* <div className="rn-brand-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h2 className="title">My Best Client</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--40 mt_sm--5 mt_md--5">
                                <Brand branstyle="branstyle--2" />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area */}

                {/* Start Portfolio Area */}
               {/*  <div className="portfolio-area pb--60 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55 mb_sm--0">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">My Latest Project</h2>
                                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="/blog"><span>View More</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Portfolio Area */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area pb--120 bg_color--1" id="contact">
                    <ContactOne />
                </div>
                {/* End Portfolio Area */}
                <FooterTwo />
                

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}

export default PersonalPortfolio;