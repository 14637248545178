import React, { Component } from "react";
import  emailjs from "emailjs-com"

class ContactOne extends Component{
    state = {
        rnName: '',
        rnEmail: '',
        rnSubject: '',
        rnMessage: '',
    }
   
    handleSubmit = async (e) => {
        e.preventDefault(); 
        
        
        var templateParams = {
            rnName: this.state.rnName,
            rnEmail: this.state.rnEmail,
            rnSubject: this.state.rnSubject,
            rnMessage: this.state.rnMessage,
        };
      
        emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, templateParams, process.env.REACT_APP_USER_ID)
            .then(function(response) {
                alert("Message Sent, I will get back to you shortly.");
            }, function(error) {
                alert("An error occurred, Please try again");
            });
        
    }
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Contact</h2>
                                
                            </div>
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-7 order-2 order-lg-1">
                            <div className="form-wrapper">

                                <form name="contact" method="POST" data-netlify="true" action="/contact/?success=true">
                                <input type="hidden" name="form-name" value="contact" />
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e)=>{this.setState({rnName: e.target.value});}}
                                            placeholder="Your Name *"
                                            required
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="email"
                                            id="item02"
                                            value={this.state.rnEmail}
                                            onChange={(e)=>{this.setState({rnEmail: e.target.value});}}
                                            placeholder="Your email *"
                                            required
                                        />
                                    </label>

                                    <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="subject"
                                            id="item03"
                                            value={this.state.rnSubject}
                                            onChange={(e)=>{this.setState({rnSubject: e.target.value});}}
                                            placeholder="Write a Subject"
                                        />
                                    </label>
                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={this.state.rnMessage}
                                            onChange={(e)=>{this.setState({rnMessage: e.target.value});}}
                                            placeholder="Your Message"
                                        />
                                    </label>
                                    <div className="text-right">
                                        <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit</button>
            
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-5 order-1 order-lg-2">
                            {/* <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src="/assets/images/about/about-6.jpg" alt="trydo"/>
                            </div> */}
                            <div className="mb-3">
                                <h5 className="mb-0">EMAIL</h5>
                                <span>jsrojasn91@gmail.com</span>
                            </div>
                            <div className="mb-3">
                                <h5 className="mb-0">CITY</h5>
                                <span>Bogota, Colombia</span>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactOne;