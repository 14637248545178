import React, {  useState, useEffect } from 'react';
import Helmet from "../component/common/Helmet"
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/FooterTwo";

 export default function  Error404  ()  {
	const [success, setSuccess] = useState(false);

	useEffect(() => {
	if ( window.location.search.includes('success=true') ) {
	setSuccess(true);
	}
	}, []);
        return (
            <>  
                <Helmet pageTitle="Juan Rojas Portfolio" />
                
		{success ? <div className="error-page-inner bg_color--4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
				
                                    <h1 className="title theme-gradient">Thanks!</h1>
                                    <h3 className="sub-title mb-2">Message Sent, I will get back to you shortly.</h3>
                                    <div className="error-button">
                                        <a className="rn-button-style--2 btn-solid" href="/">Back To Homepage</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>:<div className="error-page-inner bg_color--4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
		
                                    <h3 className="sub-title mb-2">There was a problem. Please write me to jsrojasn91@gmail.com</h3>
                                    <div className="error-button">
                                        <a className="rn-button-style--2 btn-solid" href="/">Back To Homepage</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

		
                {/* Start Page Error  */}
                
                {/* End Page Error  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 
            </>
        )

}

