import React from 'react';
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn, FaGithub} from "react-icons/fa";

const SocialShare = [
    {Social: <FaLinkedinIn /> , link: 'https://co.linkedin.com/in/juan-sebastian-rojas-navia-aa727b159/en'},
    {Social: <FaGithub /> , link: 'https://github.com/jsrojasn'},
]

const FooterTwo = () => {
    return (
        <div className="footer-style-2 ptb--30" data-black-overlay="9">
            <div className="wrapper plr--50 plr_sm--20">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner">
                            <div className="logo text-center text-sm-left mb_sm--20">
                                {/* <a href="/home-one">
                                    <img src="/assets/images/logo.jpg" alt="Logo images"/>
                                </a> */}
                                <span className="font-weight-bold" style={{color:"white", fontSize:"36px"}}>Juan <span style={{color:"rgb(219,150,0)"}}>Rojas</span></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner text-center">
                            <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                {SocialShare.map((val , i) => (
                                    <li key={i}><a href={`${val.link}`} target="blank">{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                            <div className="text">
                                <p>Bogota, Colombia.</p>
                                <p>jsrojasn91@gmail.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterTwo;