import React, { Component, Fragment } from "react";
import ScrollToTop from "react-scroll-up";
import { Link } from "react-router-dom";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import Footer from "../component/footer/Footer";
import Brand from "../elements/Brand";
import PortfolioList from "../elements/portfolio/PortfolioList";
import TabTwo from "../elements/tab/TabTwo";
import ContactOne from "../elements/contact/ContactOne";
import Helmet from "../component/common/Helmet";

const SlideList = [
  {
    textPosition: "text-left",
    category: "",
    title: "",
    description: "",
    buttonText: "",
    buttonLink: "",
  },
];
class PersonalPortfolio extends Component {
  render() {
    let title = "About Me",
      description =
        "Hi, my name is Juan Sebastian Rojas. I'm an enthusiastic Javascript Full Stack Web Developer and React Native mobile developer from Bogota, Colombia. My goal is to make real ideas from all around the world with new technologies, trends and industry advancements.";
    return (
      <Fragment>
        <Helmet pageTitle="Juan Rojas Training" />

        <Header logo="symbol-dark" color="color-black" isTraining={true} />

        {/* Start Slider Area   */}
        {SlideList.map((value, index) => (
          <div
            className="p-0 m-0 slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center"
            key={index}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className={`inner ${value.textPosition}`}>
                    {value.category ? (
                      <p className="description">{value.category}</p>
                    ) : (
                      ""
                    )}
                    {value.title ? (
                      <h1
                        className=" description title text-center"
                        dangerouslySetInnerHTML={{ __html: value.title }}
                      ></h1>
                    ) : (
                      ""
                    )}
                    {value.description ? (
                      <p className="description">{value.description}</p>
                    ) : (
                      ""
                    )}
                    {value.buttonText ? (
                      <div className="slide-btn">
                        <a
                          className="rn-button-style--2 btn-primary-color"
                          href={`${value.buttonLink}`}
                        >
                          {value.buttonText}
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* End Slider Area   */}
        {/* Start Brand Area */}
        <div className="rn-brand-area pb--120 bg_color--1" id="projects">
          <div className="container">
	    <h2 className="text-center">Training Projects</h2>
            <div className="row">
              <div className="col-lg-12 mt--40 mt_sm--5 mt_md--5">
                <div className="row align-items-center">
                  <div className="col-md-6 col-lg-4">
                    <div className="pt-3">
                      <a href="https://mydev-blog.herokuapp.com/" target="blank">
                        <img
                          src="/assets/images/training/devblog.png"
                          alt=""
			  width="100%"
                        />
                      </a>
                    </div>
                    <div className="p-2">
                      <div className="blog-post-title margin-bottom-10">
                        <h3>
                          <a href="https://mydev-blog.herokuapp.com/" target="blank">
                            Developers Blog
                          </a>
                        </h3>
                      </div>
                       <p>Web app with blog style for save and share memories of coding while you are learning. It was made using React with Firebase as real-time Database.</p>
		       <a className="mr-3" href="https://mydev-blog.herokuapp.com/" target="blank"><u>Link</u></a>
                	<a href="https://github.com/jsrojasn/React-Dev-Blog" target="blank"><u>Repository</u></a>
		    </div>
                  </div>
		  <div className="col-md-6 col-lg-4">
                    <div className="pt-3">
                      <a href="https://jsrojasn.github.io/Pagination-search/" target="blank">
                        <img
                          src="/assets/images/training/pagination.png"
                          alt=""
                          width="100%"
                        />
                      </a>
                    </div>
                    <div className="p-2">
                      <div className="blog-post-title margin-bottom-10">
                        <h3>
                          <a href="https://jsrojasn.github.io/Pagination-search/" target="blank">
                            Pagination and search
                          </a>
                        </h3>
                      </div>
                      <p>Practice and creation of pages and UI components for a student list, it also includes a search filter which results are paginated too.</p>
		      <a className="mr-3" href="https://jsrojasn.github.io/Pagination-search/" target="blank"><u>Link</u></a>
                	<a href="https://github.com/jsrojasn/Pagination-search" target="blank"><u>Repository</u></a>
		    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="pt-3">
                      <a href="https://jsrojasn.github.io/Employee-directory--usingAPI/" target="blank">
                        <img src="/assets/images/training/directory.png" alt="" width="100%"/>
                      </a>
                    </div>
                    <div className="p-2">
                      <div className="blog-post-title margin-bottom-10">
                        <h3>
                          <a
                            href="https://jsrojasn.github.io/Employee-directory--usingAPI/"
                            target="blank"
                          >
                            Employee Directory
                          </a>
                        </h3>
                      </div>
                      <p>
		      Directory for one startup. It was done using Ajax and JSON with the API of randomuser.me for practicing this method of exchanging web data.
                      </p>
		      <a className="mr-3" href="https://jsrojasn.github.io/Employee-directory--usingAPI/" target="blank"><u>Link</u></a>
                	<a href="https://github.com/jsrojasn/Employee-directory--usingAPI" target="blank"><u>Repository</u></a>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
	    <div className="row">
              <div className="col-lg-12 mt--40 mt_sm--5 mt_md--5">
                <div className="row align-items-center">
                  <div className="col-md-6 col-lg-4">
                    <div className="pt-3">
                      <a href="https://the-expensify-app-2.herokuapp.com/" target="blank">
                        <img
                          src="/assets/images/training/expensify.png"
                          alt=""
			  width="100%"
                        />
                      </a>
                    </div>
                    <div className="p-2">
                      <div className="blog-post-title margin-bottom-10">
                        <h3>
                          <a href="https://the-expensify-app-2.herokuapp.com/" target="blank">
                            Expensify
                          </a>
                        </h3>
                      </div>
                      <p>React Web app to handle your expenses includin Google authentication and real-time database with firebase and filters by amount or date for your different expenses.</p>
		      <a className="mr-3" href="https://the-expensify-app-2.herokuapp.com/" target="blank"><u>Link</u></a>
                	<a href="https://github.com/jsrojasn/React-Expensify" target="blank"><u>Repository</u></a>
		    </div>
                  </div>
		  <div className="col-md-6 col-lg-4">
                    <div className="pt-3">
                      <a href="https://jsrojasn.github.io/Tic-Tac-Toe/" target="blank">
                        <img
                          src="/assets/images/training/tictactoe.png"
                          alt=""
                          width="100%"
                        />
                      </a>
                    </div>
                    <div className="p-2">
                      <div className="blog-post-title margin-bottom-10">
                        <h3>
                          <a href="https://jsrojasn.github.io/Tic-Tac-Toe/" target="blank">
                            Tic Tac Toe
                          </a>
                        </h3>
                      </div>
                      <p>Funny Tic-Tac-Toe for two players with turns, different screens and boards for winners. It was a good practice for object-oriented JavaScript..</p>
		      <a className="mr-3" href="https://jsrojasn.github.io/Tic-Tac-Toe/" target="blank"><u>Link</u></a>
                	<a href="https://github.com/jsrojasn/Tic-Tac-Toe" target="blank"><u>Repository</u></a>
		    </div>
                  </div>
                  
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <FooterTwo />

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default PersonalPortfolio;
